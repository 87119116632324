import React, {useRef, useEffect} from 'react'
import blobCanvas from './blob-canvas'

export default function Blobs (props) {
  const container = useRef()

  useEffect(() => {
    container.current.style.opacity = 1

    var canvas = blobCanvas({
      container: container.current
    })

    return () => {
      canvas.destroy()
    }
  }, [])

  return (
    <div className="blobs" ref={container}></div>
  )
}